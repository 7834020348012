import isEnvironment from "./lib/isEnvironment";

if (import.meta.env.DEV) {
  window.REACT_APP_ENVIRONMENT = import.meta.env.REACT_APP_ENVIRONMENT;
  window.REACT_APP_API_ENVIRONMENT = import.meta.env.REACT_APP_API_ENVIRONMENT;
  window.REACT_APP_VERSION = import.meta.env.REACT_APP_VERSION;
  window.REACT_APP_PUBNUB_SUB_KEY = import.meta.env.REACT_APP_PUBNUB_SUB_KEY;
  window.REACT_APP_PUBNUB_PUB_KEY = import.meta.env.REACT_APP_PUBNUB_PUB_KEY;
  window.REACT_APP_APP_BASE_URL = import.meta.env.REACT_APP_APP_BASE_URL;
  window.REACT_APP_API_BASE_URL = import.meta.env.REACT_APP_API_BASE_URL;
  window.REACT_APP_MEDIA_BASE_URL = import.meta.env.REACT_APP_MEDIA_BASE_URL;
  window.REACT_APP_STRIPE_TOKEN = import.meta.env.REACT_APP_STRIPE_TOKEN;
  window.REACT_APP_ELECTRON_PROTOCOL =
    import.meta.env.REACT_APP_ELECTRON_PROTOCOL;
}

// we need to do this for our feature environments, to properly
// get the service workers registered
if (
  !isEnvironment("prod") &&
  !window.location.href.includes(window.REACT_APP_APP_BASE_URL)
) {
  window.REACT_APP_APP_BASE_URL = window.location.origin;
}
